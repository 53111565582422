

header {
    // filter: blur(5px);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

#menu-mobile {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}