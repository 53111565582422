// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@600&display=swap');
// $global-font-family: 'Noto Sans SC', sans-serif;

// @import url('https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap');
// $code-font-family: 'ZCOOL XiaoWei', sans-serif;

// @import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700&display=swap&subset=latin-ext');
// $code-font-family: Fira Mono, Source Code Pro, Menlo, Consolas, Monaco, monospace;

// Font and Line Height
$global-font-size: 18px;

// Color of the header background
$header-background-color: #f8f8f8a0;
$header-background-color-dark: #252627a0;
$header-background-color-black: #000000a0;
